@import url(https://fonts.googleapis.com/css?family=Fira+Mono:400,500);
* {
	box-sizing: border-box;
}

body {
	background: rgba(0, 0, 0, 0.7) !important;
	min-height: 100vh;
	display: flex;
	font-family: 'Fira Mono';
}

h3, label, span, input, option, .form-select, .form-control {
	font-weight: 400;
	font-family: 'Fira Mono';
}

body, html, .app, #root, .wrapper {
	width: 100%;
	height: 100%;
}

.form-group {
	padding-bottom: 1em;
}

label {
	padding-bottom: 0.5em;
}

.wrapper {
  	display: flex;
  	justify-content: center;
  	flex-direction: column;
  	text-align: left;
}

.inner {
  	width: 40vw;
  	margin: auto;
  	background: #ffffff;
  	box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  	padding: 40px 55px 45px 55px;
  	border-radius: 15px;
  	transition: all .3s;
}

.wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 1em;
}

.btn, .btn-content {
	color: #000000 !important;
	background-color: #ffab00 !important;
	border-color: #ffab00 !important;
	font-weight: 500 !important;
	width: 100%;
	font-family: 'Fira Mono';
}

.btn-content {
	font-weight: 500 !important;
}

.form-check-input:checked {
	background-color: #ffab00 !important;
	border-color: #ffab00 !important;
}

.datepicker-container {
	font-family: 'Fira Mono';
	margin-bottom: 4px;
}

.datepicker-header {
	border-bottom: 1px solid black;
	margin-bottom: 4px;
	font-weight: 500 !important;
}

.datepicker-month-row {
	margin: 0 !important;
}

.datepicker-month-col {
	padding: 0 !important;
}

.datepicker-row {
	margin: 0 !important;
}

.datepicker-col {
	padding: 0 !important;
	text-align: center;
}

.datepicker-pointer {
	cursor: pointer;
}

.datepicker-selected {
	background-color: #ffab00 !important;
	border-color: #ffab00 !important;
	border-radius: 0.25rem;
	font-weight: 500 !important;
}

